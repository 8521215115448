<template>
    <ul v-if="items && items.length">
        <li
            v-for="item in items"
            :key="`label-item-${item.id}`"
        >
            <y-form-field
                v-model="model[item.id]"
                type="checkbox"
                :name="`label-${item.id}`"
                :label="item.title"
            />

            <y-nested-loop
                v-if="item.children && item.children.length"
                v-model="model"
                :items="item.children"
            />
        </li>
    </ul>
</template>

<script>

    export default {

        name: 'YNestedLoop',

        components: {
            YFormField: () => import('@deps/form/FormField'),
        },

        props: {
            /**
             * Label Items
             */
            items: {
                required: true,
                type    : Array,
            },

            /**
             * Input Value
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: null,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.setDefaults(),
            };
        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch value set new model
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        methods: {
            /**
             * Set default value of checkboxes
             */
            setDefaults() {
                if (!this.value) {
                    return {};
                }

                if (Array.isArray(this.value)) {
                    if (this.value.length) {
                        return this.value.reduce((obj, item) => ({
                            ...obj,
                            [item]: 1,
                        }), {});
                    }
                    return {};
                }

                return this.value;
            },
        },

    };
</script>
